var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "in-layout",
    [
      _c(
        "template",
        { slot: "list" },
        [
          _c(
            "v-container",
            { staticClass: "px-4", attrs: { "grid-list-md": "" } },
            [
              _c(
                "v-layout",
                { staticClass: "ma-2", attrs: { column: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "router-link",
                        {
                          staticStyle: { cursor: "pointer" },
                          attrs: { tag: "span", to: { name: "Configurações" } },
                        },
                        [
                          _c("v-icon", { attrs: { size: "18" } }, [
                            _vm._v("mdi-chevron-left"),
                          ]),
                          _vm._v(
                            "\n            Voltar para configurações\n          "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h1", [_vm._v("Usuários cadastrados")]),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        "align-center": "",
                        "justify-space-between": "",
                      },
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              name: "search",
                              label: "Pesquisar usuário",
                              "prepend-inner-icon": "mdi-magnify",
                              clearable: "",
                            },
                            on: {
                              "click:clear": function ($event) {
                                return _vm.typing()
                              },
                              input: function ($event) {
                                return _vm.typing()
                              },
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs3: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: { "justify-end": "", "align-center": "" },
                            },
                            [
                              _vm._v(
                                "\n              Exibindo " +
                                  _vm._s(_vm.showingStart) +
                                  " - " +
                                  _vm._s(_vm.showingEnd) +
                                  " de " +
                                  _vm._s(_vm.totalItems) +
                                  "\n              "
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    disabled: _vm.pageNumber <= 1,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.pageNumber -= 1
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    disabled: _vm.pageNumber >= _vm.totalPages,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.pageNumber += 1
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.users,
                  loading: _vm.loading,
                  "hide-actions": "",
                  "no-data-text": "Nenhum usuário encontrado",
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("td", [_vm._v(_vm._s(props.item.id))]),
                        _c("td", [
                          _vm._v(_vm._s(_vm.formatCpfCnpj(props.item.cpf))),
                        ]),
                        _c("td", [_vm._v(_vm._s(props.item.name))]),
                        _c(
                          "td",
                          { staticClass: "text-xs-right" },
                          [
                            _c(
                              "v-btn",
                              { attrs: { small: "", icon: "", flat: "" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewUser(props.item.id)
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-eye")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }